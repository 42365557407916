// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React, { useEffect, useMemo } from 'react';
import { Typography, Layout } from 'antd';
import { Link, Redirect } from 'react-router-dom';
import { Button } from '@imprivata-cloud/astra-ui-component-library';
import SetupStep from '../../components/SetupStep';
import SetupStepHeader from '../../components/SetupStepHeader';
import storage from '../../utils/storage';
import useQueryParams from '../../hooks/useQueryParams';
import { getTenantHeaderInterceptor } from '../../service/interceptors';
import { StorageKeys } from '../../constants/fieldNames';
import rxClient from '../../service/rxClient';

import classes from './steps.module.less';
import {
  INITIAL_ADMIN_CREDS_ROUTE,
  ENTRA_ID_AD_CONNECT_ROUTE,
} from '../../constants/routes';
import withTracing from '../../tracing/withTracing';
import { useTranslation, Trans } from 'react-i18next';
import backgroundImage from '../../assets/astra_setup_d.svg';

const { Paragraph } = Typography;
const { Content } = Layout;

const UpdatePoliciesStep: React.FC = function () {
  const { t } = useTranslation();
  const aadTenantIdFromStorage = useMemo(() => {
    return storage.getItem(StorageKeys.AAD_TENANT_ID);
  }, []);
  const queryParams = useQueryParams();

  useEffect(() => {
    const tenantId = storage.getItem(StorageKeys.TENANT_ID);
    if (tenantId) {
      rxClient.client.interceptors.request.use(
        getTenantHeaderInterceptor(tenantId),
      );
    }
  }, []);

  if (queryParams.error) {
    return (
      <Redirect
        to={{
          pathname: ENTRA_ID_AD_CONNECT_ROUTE,
          state: {
            aadTenantId: aadTenantIdFromStorage,
            entraIdError: 'PERMISSION_DENIED',
          },
        }}
      />
    );
  }

  return (
    <SetupStep
      data-testid="step-update-policies"
      className={classes.addTrustLocation}
      rightPartBackground={backgroundImage}
    >
      <SetupStepHeader title={t('steps.update-policies.title-label')} />
      <Content data-testid="step-description">
        <Paragraph className="primary-body">
          {t('steps.update-policies.label.paragraph-1')}
          <a
            className={classes.hyperLink}
            target="_blank"
            rel="noopener noreferrer"
            href={t('steps.update-policies.label.paragraph-1-link-url')}
          >
            {t('steps.update-policies.label.paragraph-1-link-title')}
          </a>
        </Paragraph>
        <Paragraph className="primary-body">
          <Trans i18nKey="steps.update-policies.label.paragraph-2" />
        </Paragraph>
        <Paragraph className="primary-body">
          <Trans i18nKey="steps.update-policies.label.paragraph-3" />
        </Paragraph>
        <Paragraph className={classes.button}>
          <Link to={INITIAL_ADMIN_CREDS_ROUTE + window.location.search}>
            <Button
              autoFocus
              label={t('steps.update-policies.next-btn')}
              type="primary"
              size="large"
              astraButtonType={['major']}
              data-testid="proceed-btn"
            />
          </Link>
        </Paragraph>
      </Content>
    </SetupStep>
  );
};

export default withTracing(UpdatePoliciesStep);
