// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import ErrorImpl from '../../service/errorImpl';
import { Action } from '../rootAction';
import {
  completeSetup,
  startSetup,
  clearAADLinkError,
  clearAdminAuthError,
  clearSessionId,
  getConsentUrl,
  setSessionId,
  clearStartSetupError,
  dpaCheck,
  dpaSubmit,
} from './actions';
import { CheckDpaConsentResponseActionType } from './types';

export const initialSessionId = '';

export const sessionId = createReducer<string, Action>(initialSessionId)
  .handleAction(setSessionId, (_, { payload: sid }) => sid)
  .handleAction(clearSessionId, () => initialSessionId);

export const aadLinkPending = createReducer<boolean, Action>(false)
  .handleAction(getConsentUrl.request, () => true)
  .handleAction(
    [getConsentUrl.success, getConsentUrl.failure, getConsentUrl.cancel],
    () => false,
  );
export const aadLinkRequestError = createReducer<ErrorImpl | null, Action>(null)
  .handleAction([getConsentUrl.request, clearAADLinkError], () => null)
  .handleAction(getConsentUrl.failure, (_, { payload: err }) => err);

export const adminAuthPending = createReducer<boolean, Action>(false)
  .handleAction([dpaCheck.request, completeSetup.request], () => true)
  // Note that dpaCheck.failure is a showstopper
  .handleAction(
    [dpaCheck.success, completeSetup.success, completeSetup.failure],
    () => false,
  );
export const dpaCheckError = createReducer<ErrorImpl | null, Action>(null)
  .handleAction([dpaCheck.request, clearAdminAuthError], () => null)
  .handleAction(dpaCheck.failure, (_, { payload: err }) => err);
export const dpaSubmitError = createReducer<ErrorImpl | null, Action>(null)
  .handleAction([dpaSubmit.request, clearAdminAuthError], () => null)
  .handleAction(dpaSubmit.failure, (_, { payload: err }) => err);

export const dpaNoActionNeeded = createReducer<boolean, Action>(
  false,
).handleAction(
  dpaCheck.success,
  (_, { payload: actionType }) =>
    actionType === CheckDpaConsentResponseActionType.NONE,
);
export const adminAuthError = createReducer<ErrorImpl | null, Action>(null)
  .handleAction([completeSetup.request, clearAdminAuthError], () => null)
  .handleAction(completeSetup.failure, (_, { payload: err }) => err);

export const startSetupPending = createReducer<boolean, Action>(false)
  .handleAction(startSetup.request, () => true)
  .handleAction([startSetup.success, startSetup.failure], () => false);
export const startSetupSuccess = createReducer<string, Action>(
  initialSessionId,
).handleAction(startSetup.success, (_, { payload: sid }) => {
  return sid;
});
export const startSetupError = createReducer<ErrorImpl | null, Action>(null)
  .handleAction([startSetup.request, clearStartSetupError], () => null)
  .handleAction(startSetup.failure, (_, { payload: err }) => err);

export default combineReducers({
  sessionId,
  aadLinkPending,
  aadLinkRequestError,
  adminAuthPending,
  dpaNoActionNeeded,
  dpaCheckError,
  dpaSubmitError,
  adminAuthError,
  startSetupPending,
  startSetupError,
  startSetupSuccess,
});
