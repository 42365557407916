// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { Action } from '../rootAction';
import {
  reloadAppPageCancel,
  reloadAppPageConfirm,
  serviceGoneSignal,
} from './actions';

export const pageReloadModalReducer = createReducer<boolean, Action>(false)
  .handleAction(serviceGoneSignal, () => true)
  .handleAction([reloadAppPageConfirm, reloadAppPageCancel], () => false);

export default combineReducers({
  pageReloadModal: pageReloadModalReducer,
});
