// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* stylelint-disable declaration-no-important */\n.OO38XwJY_4v9CtJlqXRc {\n  font-size: 20px;\n  font-weight: 600;\n  padding: 1px 6px;\n  color: #33375B;\n  background: #dddeea;\n  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);\n}\n", "",{"version":3,"sources":["webpack://./src/components/TestTenantLabel.module.less"],"names":[],"mappings":"AAAA,+CAA+C;AAG/C;EACE,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,cAAA;EACA,mBAAA;EACA,uCAAA;AADF","sourcesContent":["/* stylelint-disable declaration-no-important */\n// Copyright 2022, Imprivata, Inc.  All rights reserved.\n\n.testTenantLabel {\n  font-size: 20px;\n  font-weight: 600;\n  padding: 1px 6px;\n  color: #33375B;\n  background: #dddeea;\n  box-shadow: 0 0 5px rgb(0 0 0 / 25%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"testTenantLabel": "OO38XwJY_4v9CtJlqXRc"
};
export default ___CSS_LOADER_EXPORT___;
