// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import clsx from 'clsx';
import { Menu, Layout } from 'antd';
import Icon from '@ant-design/icons';
import { ReactComponent as AstraSvg } from '../assets/icons/star.svg';
import { ReactComponent as SecuritySvg } from '../assets/icons/security.svg';
import { ReactComponent as UsersSvg } from '../assets/icons/users.svg';
import { ReactComponent as DirectoriesSvg } from '../assets/icons/directories.svg';
import { ReactComponent as EndpointsSvg } from '../assets/icons/endpoints.svg';
import { ReactComponent as AppsSvg } from '../assets/icons/apps.svg';
import { ReactComponent as SettingsSvg } from '../assets/icons/settings.svg';
import { ReactComponent as LogoutSvg } from '../assets/icons/logout.svg';

import classes from './SiderMenu.module.less';
import { useTranslation } from 'react-i18next';

const { Sider } = Layout;

interface SiderMenuProps {
  siderProps?: React.ComponentProps<typeof Sider>;
  menuProps?: React.ComponentProps<typeof Menu>;
}

const SiderMenu: React.FC<SiderMenuProps> = function SiderMenu({
  siderProps = {},
  menuProps = {},
}) {
  const { t } = useTranslation();
  const { collapsedWidth = 64, ...restSiderProps } = siderProps;
  const {
    mode,
    children,
    className: menuClassName,
    ...restMenuProps
  } = menuProps;

  return (
    <Sider
      theme="dark"
      collapsed
      collapsedWidth={collapsedWidth}
      collapsible={false}
      className={classes.sider}
      {...restSiderProps}
    >
      <Menu
        theme="dark"
        mode="inline"
        className={clsx(classes.menu, menuClassName)}
        {...restMenuProps}
      >
        <Menu.ItemGroup className={classes.topGroup}>
          <Menu.Item
            className={classes.menuItem}
            key="home"
            icon={<Icon component={AstraSvg} />}
            data-testid="side-menu-button--home"
          >
            {t('navigation.astra')}
          </Menu.Item>
        </Menu.ItemGroup>
        <Menu.ItemGroup className={classes.midGroup}>
          <Menu.Item
            className={classes.menuItem}
            key="security"
            icon={<Icon component={SecuritySvg} />}
            data-testid="side-menu-button--security"
          >
            {t('navigation.security')}
          </Menu.Item>
          <Menu.Item
            className={classes.menuItem}
            key="users"
            icon={<Icon component={UsersSvg} />}
            data-testid="side-menu-button--users"
          >
            {t('navigation.users')}
          </Menu.Item>
          <Menu.Item
            className={classes.menuItem}
            key="directories"
            icon={<Icon component={DirectoriesSvg} />}
            data-testid="side-menu-button--directories"
          >
            {t('navigation.directories')}
          </Menu.Item>
          <Menu.Item
            className={classes.menuItem}
            key="endpoints"
            icon={<Icon component={EndpointsSvg} />}
            data-testid="side-menu-button--endpoints"
          >
            {t('navigation.endpoints')}
          </Menu.Item>
          <Menu.Item
            className={classes.menuItem}
            key="apps"
            icon={<Icon component={AppsSvg} />}
            data-testid="side-menu-button--apps"
          >
            {t('navigation.applications')}
          </Menu.Item>
        </Menu.ItemGroup>
        <Menu.ItemGroup className={classes.bottomGroup}>
          <Menu.Item
            className={classes.menuItem}
            key="settings"
            icon={<Icon component={SettingsSvg} />}
            data-testid="side-menu-button--settings"
          >
            {t('navigation.settings')}
          </Menu.Item>
          <Menu.Item
            className={classes.menuItem}
            key="logout"
            icon={<Icon component={LogoutSvg} />}
            data-testid="side-menu-button--logout"
          >
            {t('navigation.logout')}
          </Menu.Item>
        </Menu.ItemGroup>
      </Menu>
    </Sider>
  );
};

export default SiderMenu;
