// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import classes from './SetupStepHeader.module.less';

interface StepHeaderProps {
  title: string;
}

const SetupStepHeader: React.FC<StepHeaderProps> = function SetupStepHeader({
  title,
}) {
  return (
    <div className={classes.header}>
      <h1 className="h1-header" data-testid="page-header-title">
        {title}
      </h1>
    </div>
  );
};

export default SetupStepHeader;
