// Copyright 2022, Imprivata, Inc.  All rights reserved.

import getConfig from '../appEnvConfig';

export const API_URL = getConfig().API_URL;
export const ADMIN_UI_URL = getConfig().ADMIN_UI_URL;
export const API_TIMEOUT = 10000; // ms
export const LOCAL_TRACING_URL = getConfig().LOCAL_TRACING_URL;

export const HTTP_STATUS_SERVICE_GONE = 410;
export const HTTP_STATUS_UNAUTHORIZED = 401;

export const headers = {
  ImprClientName: 'Impr-Client-Name',
  ImprSessionId: 'Impr-Session-Id',
  ImprTenantId: 'Impr-Tenant-Id',
  ImprCodingCtx: 'Impr-Coding-Ctx',
  AuthnAPIVersion: 'AuthnAPIVersion',
  // Response headers (only lowercase works)
  ImprTenantType: 'impr-tenant-type',
} as const;

export const INITIAL_SETUP_PREFIX = '/initial-setup-web';

export const initialSetupUrl = {
  START_SETUP: `${INITIAL_SETUP_PREFIX}/v3/initial-setup/start`,
  COMPLETE_SETUP: `${INITIAL_SETUP_PREFIX}/v3/tenant-initial-setup/complete`,
  AAD_LINK_URL: `${INITIAL_SETUP_PREFIX}/v3/aad-consent-url/get`,
  INITIAL_GROUP_SYNC_START: `${INITIAL_SETUP_PREFIX}/v3/initial-group-sync/start`,
};

export const directories = {
  // This endpoint is a proxy for /admin-web/directory/v2/directories/find
  // so that we can use initial-setup-web session
  FIND: `${INITIAL_SETUP_PREFIX}/directory/v2/directories/find`,
};

export const USER_CONSENT_PREFIX = '/user-consent';

export const consentUrl = {
  DPA_CHECK: `${INITIAL_SETUP_PREFIX}${USER_CONSENT_PREFIX}/v2/consent/dpa-check`,
  DPA_SUBMIT: `${INITIAL_SETUP_PREFIX}${USER_CONSENT_PREFIX}/v2/consent/dpa-submit`,
};
