// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider as ReduxProvider } from 'react-redux';
import { Router } from 'react-router-dom';
import AppLayout from './containers/AppLayout';
import ConfigProvider from './containers/ConfigProvider';
import i18next from './i18n';
import history from './routes/history';
import { applyInterceptors } from './service/interceptors';
import configureStore from './store';

const store = configureStore({
  isProduction: process.env.NODE_ENV === 'production',
  module,
  history,
});
applyInterceptors(store.dispatch);

const App: React.FC = function App() {
  return (
    <ReduxProvider store={store}>
      <I18nextProvider i18n={i18next}>
        <ConfigProvider>
          <Router history={history}>
            <AppLayout />
          </Router>
        </ConfigProvider>
      </I18nextProvider>
    </ReduxProvider>
  );
};

export default App;
