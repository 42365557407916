// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { createAction } from 'typesafe-actions';

export const serviceGoneSignal = createAction(
  'service/HTTP_STATUS_GONE',
)<void>();
export const reloadAppPageConfirm = createAction(
  'app/RELOAD_PAGE_CONFIRM',
)<void>();
export const reloadAppPageCancel = createAction(
  'app/RELOAD_PAGE_CANCEL',
)<void>();
