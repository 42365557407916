// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { combineEpics, Epic } from 'redux-observable';
import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
  mapTo,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import Cookie from 'js-cookie';
import {
  adminUiTenantIdParam,
  sessionIdParam,
  StorageKeys,
  adminUsernameField,
} from '../../constants/fieldNames';
import {
  HTTP_STATUS_UNAUTHORIZED,
  ADMIN_UI_URL,
} from '../../constants/services';
import ErrorImpl from '../../service/errorImpl';
import {
  completeSetup$,
  findDirectories$,
  getConsentUrl$,
  groupSyncStart$,
  startSetup$,
  dpaCheck$,
  dpaSubmit$,
} from '../../service/initialSetup';
import { getSessionHeaderInterceptor } from '../../service/interceptors';
import { Action } from '../rootAction';
import {
  completeSetup,
  clearSessionId,
  getConsentUrl,
  invalidateSession,
  setSessionId,
  startSetup,
  sessionExpired,
  linkDeadSetupAlreadyComplete,
  findDirectories,
  groupSyncStart,
  dpaCheck,
  dpaSubmit,
} from './actions';
import {
  INVALID_STATE_ROUTE,
  LINK_DEAD_ROUTE,
  SESSION_EXPIRED_ROUTE,
} from '../../constants/routes';
import { History } from 'history';
import rxClient from '../../service/rxClient';
import getConfig from '../../appEnvConfig';
import storage from '../../utils/storage';
import { initialSessionId } from './reducer';
import { AxiosError } from 'axios';

const { HTTPS } = getConfig();
let reqSessionInterceptorId: number;

export const setSessionEpic: Epic<Action> = action$ =>
  action$.pipe(
    filter(isActionOf(setSessionId)),
    take(1),
    tap({
      next: ({ payload: sessionId }) => {
        Cookie.set(sessionIdParam, sessionId, {
          expires: new Date(Date.now() + 15 * 60 * 1000),
          sameSite: 'strict',
          secure: HTTPS,
        });
        reqSessionInterceptorId = rxClient.client.interceptors.request.use(
          getSessionHeaderInterceptor(sessionId),
        );
      },
    }),
  );
export const clearSessionEpic: Epic<Action> = action$ =>
  action$.pipe(
    filter(isActionOf(clearSessionId)),
    take(1),
    tap({
      next: () => {
        Cookie.remove(sessionIdParam);
        rxClient.client.interceptors.request.eject(reqSessionInterceptorId);
      },
    }),
  );

export const handleUnauthorizedEpic: Epic<Action> = action$ =>
  action$.pipe(
    filter(
      ({ payload }) =>
        payload instanceof ErrorImpl &&
        payload.httpStatus === HTTP_STATUS_UNAUTHORIZED,
    ),
    take(1),
    mapTo(invalidateSession()),
  );

export const invalidSessionEpic: Epic<Action> = (
  action$,
  _,
  { history }: { history: History },
) =>
  action$.pipe(
    filter(isActionOf(invalidateSession)),
    take(1),
    tap({
      next: () => {
        history.replace(INVALID_STATE_ROUTE);
      },
    }),
    mapTo(clearSessionId()),
  );

export const sessionExpiredEpic: Epic<Action> = (
  action$,
  _,
  { history }: { history: History },
) =>
  action$.pipe(
    filter(isActionOf(sessionExpired)),
    tap(() => {
      history.replace(SESSION_EXPIRED_ROUTE);
    }),
    mapTo(clearSessionId()),
  );

export const linkDeadEpic: Epic<Action> = (
  action$,
  _,
  { history }: { history: History },
) =>
  action$.pipe(
    filter(isActionOf(linkDeadSetupAlreadyComplete)),
    tap(() => {
      history.replace(LINK_DEAD_ROUTE);
    }),
    mapTo(clearSessionId()),
  );

export const getConsentUrlEpic: Epic<Action> = action$ =>
  action$.pipe(
    filter(isActionOf(getConsentUrl.request)),
    switchMap(({ payload }) =>
      getConsentUrl$(payload).pipe(
        map(res => getConsentUrl.success(res.aadConsentUrl)),
        tap({
          next: ({ payload: aadConsentUrl }) => {
            if (aadConsentUrl) {
              window.location.assign(aadConsentUrl);
            }
          },
        }),
        catchError((error: Error) =>
          of(getConsentUrl.failure(ErrorImpl.from(error))),
        ),
        takeUntil(action$.pipe(filter(isActionOf(getConsentUrl.cancel)))),
      ),
    ),
  );

export const dpaCheckEpic: Epic<Action> = action$ =>
  action$.pipe(
    filter(isActionOf(dpaCheck.request)),
    switchMap(({ payload }) =>
      dpaCheck$(payload).pipe(
        map(res => dpaCheck.success(res.actionType)),
        catchError((error: Error) =>
          of(dpaCheck.failure(ErrorImpl.from(error))),
        ),
      ),
    ),
  );

export const dpaSubmitEpic: Epic<Action> = action$ =>
  action$.pipe(
    filter(isActionOf(dpaSubmit.request)),
    switchMap(({ payload }) => {
      return dpaSubmit$(payload).pipe(
        map(() => dpaSubmit.success()),
        map(() =>
          completeSetup.request({
            [adminUsernameField]: payload.userId,
            [StorageKeys.CODING_CTX]: storage.getItem(StorageKeys.CODING_CTX),
          }),
        ),
        catchError((error: Error) =>
          of(dpaSubmit.failure(ErrorImpl.from(error))),
        ),
      );
    }),
  );

export const completeSetupEpic: Epic<Action> = action$ =>
  action$.pipe(
    filter(isActionOf(completeSetup.request)),
    switchMap(({ payload }) =>
      completeSetup$(payload).pipe(
        map(() => {
          return completeSetup.success();
        }),
        catchError((error: Error) => {
          return of(completeSetup.failure(ErrorImpl.from(error)));
        }),
      ),
    ),
  );

export const findDirectoriesEpic: Epic<Action> = action$ =>
  action$.pipe(
    filter(isActionOf(completeSetup.success)),
    switchMap(() =>
      findDirectories$().pipe(
        map(res => {
          const dirId = res.directories[0]?.directoryId;
          if (!dirId) {
            throw new Error('Failed to get directoryId.');
          }
          return findDirectories.success(dirId);
        }),
        catchError((error: Error) => {
          redirectToAdminUI();
          return of(findDirectories.failure(ErrorImpl.from(error)));
        }),
      ),
    ),
  );

export const groupSyncStartEpic: Epic<Action> = action$ =>
  action$.pipe(
    filter(isActionOf(findDirectories.success)),
    switchMap(({ payload: directoryId }) =>
      groupSyncStart$(directoryId).pipe(
        map(() => {
          redirectToAdminUI();
          return groupSyncStart.success();
        }),
        catchError((error: Error) => {
          redirectToAdminUI();
          return of(groupSyncStart.failure(ErrorImpl.from(error)));
        }),
      ),
    ),
  );

function redirectToAdminUI() {
  const tenantId = storage.getItem(StorageKeys.TENANT_ID);
  if (tenantId) {
    storage.clear();
    window.location.assign(
      `${ADMIN_UI_URL}?${adminUiTenantIdParam}=${tenantId}`,
    );
  }
}

export const startSetupEpic: Epic<Action> = action$ =>
  action$.pipe(
    filter(isActionOf(startSetup.request)),
    switchMap(({ payload }) =>
      startSetup$(payload).pipe(
        map(() => {
          return startSetup.success(initialSessionId);
        }),
        catchError((error: Error | AxiosError) => {
          return of(startSetup.failure(ErrorImpl.from(error)));
        }),
        takeUntil(action$.pipe(filter(isActionOf(startSetup.cancel)))),
      ),
    ),
  );

export default combineEpics(
  setSessionEpic,
  clearSessionEpic,
  handleUnauthorizedEpic,
  invalidSessionEpic,
  sessionExpiredEpic,
  linkDeadEpic,
  getConsentUrlEpic,
  dpaCheckEpic,
  dpaSubmitEpic,
  completeSetupEpic,
  findDirectoriesEpic,
  groupSyncStartEpic,
  startSetupEpic,
);
