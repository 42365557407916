// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { DocumentLoadInstrumentation } from '@opentelemetry/instrumentation-document-load';
import { WebTracerProvider } from '@opentelemetry/web';
import { ZipkinExporter } from '@opentelemetry/exporter-zipkin';

import { SimpleSpanProcessor, Tracer } from '@opentelemetry/tracing';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import { BaseOpenTelemetryComponent } from '@opentelemetry/plugin-react-load';
import { B3Propagator } from '@opentelemetry/propagator-b3';
import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request';
import { API_URL, LOCAL_TRACING_URL } from '../constants/services';
import { UserInteractionInstrumentation } from '@opentelemetry/instrumentation-user-interaction';

export default function tracingSetup(serviceName: string): Tracer {
  // Minimum required setup - supports only synchronous operations
  const providerWithZone = new WebTracerProvider();

  //Zipkin
  //Pipes trace output to the local Zipkin instance
  //This url will need to changed to our actual output url per DevOps when their implementation is ready
  // TODO: Use env variable for this URL instead of hardcoded value, see appEnvConfig.ts
  const zipkinConfig = {
    url: LOCAL_TRACING_URL,
  };

  //If there is a Zipkin path set, it will use that, otherwise, it will default to exporting into the browser console
  //empty string is the default for the environment variable
  if (LOCAL_TRACING_URL !== '') {
    providerWithZone.addSpanProcessor(
      new SimpleSpanProcessor(new ZipkinExporter(zipkinConfig)),
    );
  }
  //End Zipkin

  // Uncomment to debug (you need to import ConsoleSpanExporter)
  //
  // providerWithZone.addSpanProcessor(
  //   new SimpleSpanProcessor(new ConsoleSpanExporter()),
  // );

  providerWithZone.register({
    contextManager: new ZoneContextManager(),
    propagator: new B3Propagator(),
  });

  registerInstrumentations({
    instrumentations: [
      new XMLHttpRequestInstrumentation({
        ignoreUrls: [/localhost:8090\/sockjs-node/],
        propagateTraceHeaderCorsUrls: [API_URL],
      }),
      new DocumentLoadInstrumentation(),
      new UserInteractionInstrumentation(),
    ],
    tracerProvider: providerWithZone,
  });

  const tracer = providerWithZone.getTracer(serviceName);

  BaseOpenTelemetryComponent.setTracer(serviceName);

  return tracer;
}
