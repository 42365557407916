// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { headers } from '../constants/services';
import { InternalAxiosRequestConfig, AxiosError } from 'axios';
import client from './client';
import { AppDispatch } from '../store/rootAction';
import {
  linkDeadSetupAlreadyComplete,
  sessionExpired,
} from '../store/initialSetup/actions';
import { ApiResponseData, ErrorCode } from './types';

export const getSessionHeaderInterceptor =
  (sessionId?: string) =>
  (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    config.headers = config.headers || {};
    config.headers[headers.ImprSessionId] = sessionId || '';

    return config;
  };

export const getTenantHeaderInterceptor =
  (tenantId?: string) =>
  (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    config.headers = config.headers || {};
    config.headers[headers.ImprTenantId] = tenantId || '';

    return config;
  };

/**
 * Please note that when you just return,
 * there's no automatic exception throwing in Axios later -
 * you basically suppress the error and let the flow go on normally.
 * You must throw an error at some point only then axios call will re-throw.
 */
export function onRejectedInterceptor(dispatch: AppDispatch) {
  return (axiosError: AxiosError<ApiResponseData>): void => {
    if (axiosError.response) {
      const { status, data } = axiosError.response;

      const invalidSessionLogoutCodes = [
        'session-expired',
        'invalid-session-id',
      ];
      if (
        status === 401 ||
        invalidSessionLogoutCodes.includes(data?.error?.code as string)
      ) {
        dispatch(sessionExpired());
        return;
      }

      if (
        status === 400 &&
        data?.error?.code === ErrorCode.INVALID_TENANT_STATE
      ) {
        dispatch(linkDeadSetupAlreadyComplete());
        return;
      }
    }

    throw axiosError;
  };
}

let responseInterceptors: number[] = [];

export const applyInterceptors = (dispatch: AppDispatch): void => {
  responseInterceptors.push(
    client.interceptors.response.use(
      undefined,
      onRejectedInterceptor(dispatch),
    ),
  );
};

export const ejectInterceptors = (): void => {
  responseInterceptors.forEach(interceptor =>
    client.interceptors.response.eject(interceptor),
  );
  responseInterceptors = [];
};
