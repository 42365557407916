// Copyright 2022, Imprivata, Inc.  All rights reserved.
import { Layout, Spin } from 'antd';
import { Redirect, Route, Switch } from 'react-router-dom';
import GetStartedStep from './steps/GetStartedStep';
import NotFoundPage from './NotFoundPage/NotFoundPage';
import ReloadPageConfirmModal from './ReloadPageConfirmModal/ReloadPageConfirmModal';
import FullPageError from './steps/FullPageError';

import classes from './AppLayout.module.less';
import SiderMenu from '../components/SiderMenu';
import SetupLayout from '../components/SetupLayout';
import {
  ADD_TRUSTED_LOCATION_ROUTE,
  ENTRA_ID_AD_CONNECT_ROUTE,
  CONTINUE_AS_INITIAL_ADMIN_ROUTE,
  GET_STARTED_ROUTE,
  HOME_ROUTE,
  INITIAL_ADMIN_CREDS_ROUTE,
  INVALID_STATE_ROUTE,
  LINK_DEAD_ROUTE,
  SESSION_EXPIRED_ROUTE,
} from '../constants/routes';
import ConnectAADStep from './steps/CaptureAADStep';
import withTracing from '../tracing/withTracing';
import InitialAdminCredsStep from './steps/InitialAdminCredsStep';
import AddTrustedLocationStep from './steps/AddTrustedLocationStep';
import UpdatePoliciesStep from './steps/UpdatePoliciesStep';
import { useTranslation } from 'react-i18next';
import { useTranslatedTitle } from '../hooks/translations';
import InvalidSession from './steps/InvalidSession';

const siderMenuProps = {
  siderProps: {
    collapsed: true,
    'data-testid': 'app-sider',
  },
  menuProps: {
    selectable: false,
    disabled: true,
    defaultSelectedKeys: ['directories'],
    'data-testid': 'app-sider-menu',
  },
};

const { Content } = Layout;

const AppLayout: React.FC = function AppLayout() {
  const { ready } = useTranslation();
  useTranslatedTitle();

  return (
    <Layout data-testid="layout" className={classes.appLayout}>
      {ready ? (
        <>
          <SiderMenu {...siderMenuProps} />
          <Switch>
            <Route
              exact
              path={INVALID_STATE_ROUTE}
              component={InvalidSession}
            />
            <Route
              exact
              path={SESSION_EXPIRED_ROUTE}
              component={InvalidSession}
            />
            <SetupLayout renderTitle={true}>
              <Switch>
                <Route exact path={HOME_ROUTE}>
                  <Redirect to={GET_STARTED_ROUTE} />
                </Route>
                <Route
                  exact
                  path={GET_STARTED_ROUTE}
                  component={GetStartedStep}
                />
                <Route
                  exact
                  path={ADD_TRUSTED_LOCATION_ROUTE}
                  component={AddTrustedLocationStep}
                />
                <Route
                  exact
                  path={ENTRA_ID_AD_CONNECT_ROUTE}
                  component={ConnectAADStep}
                />
                <Route
                  exact
                  path={CONTINUE_AS_INITIAL_ADMIN_ROUTE}
                  component={UpdatePoliciesStep}
                />
                <Route
                  exact
                  path={INITIAL_ADMIN_CREDS_ROUTE}
                  component={InitialAdminCredsStep}
                />
                <Route exact path={LINK_DEAD_ROUTE} component={FullPageError} />
                <Route component={NotFoundPage} />
              </Switch>
            </SetupLayout>
          </Switch>
          <ReloadPageConfirmModal />
        </>
      ) : (
        <Content className={classes.loading} data-testid="loading">
          <Spin />
        </Content>
      )}
    </Layout>
  );
};

export default withTracing(AppLayout);
