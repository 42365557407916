// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import { ConfigProvider as AntConfigProvider } from 'antd';
import { useLanguageDir, useValidationMessages } from '../hooks/translations';

const ConfigProvider: React.FC<
  React.ComponentProps<typeof AntConfigProvider>
> = props => {
  const validateMessages = useValidationMessages();

  return (
    <AntConfigProvider
      form={{ validateMessages }}
      direction={useLanguageDir()}
      {...props}
    />
  );
};

export default ConfigProvider;
