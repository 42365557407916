// Copyright 2022, Imprivata, Inc.  All rights reserved.

export const HOME_ROUTE = '/';
export const GET_STARTED_ROUTE = '/get-started'; // landing page
export const INVALID_STATE_ROUTE = '/invalid-state'; // in case of landing page error OR tenant was already set up or have other invalid state
export const SESSION_EXPIRED_ROUTE = '/session-expired'; // in case of session id expiration
export const LINK_DEAD_ROUTE = '/link-dead'; // in case of session id expiration
export const ENTRA_ID_AD_CONNECT_ROUTE = '/aad-connect'; // page collecting AAD tenant id
export const ADD_TRUSTED_LOCATION_ROUTE = '/aad-trusted-location'; // page with instructions
export const CONTINUE_AS_INITIAL_ADMIN_ROUTE = '/continue-as-initial-admin'; // AAD access granted successfully, grab initial admin creds
export const INITIAL_ADMIN_CREDS_ROUTE = '/identify-an-initial-administrator'; // AAD access granted successfully, grab initial admin creds
export const ENTRA_ID_AD_CONSENT_FAILED_ROUTE = '/aad-failure'; // AAD login failed or access was not granted
