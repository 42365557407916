// Copyright 2022, Imprivata, Inc.  All rights reserved.

import translation from '../assets/locales/en/translation.json';

export const defaultNS = 'translation' as const;
export const resources = {
  en: {
    translation,
  },
} as const;
