// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form, InputRef, Layout, Typography } from 'antd';
import {
  Banner,
  Button,
  destroyBanner,
  InputBox,
} from '@imprivata-cloud/astra-ui-component-library';
import SetupStep from '../../components/SetupStep';
import SetupStepHeader from '../../components/SetupStepHeader';
import { aadTenantIdField } from '../../constants/fieldNames';
import { useAADLinkRequest } from '../../hooks/aadStepHooks';
import withTracing from '../../tracing/withTracing';

import backgroundImage from '../../assets/astra_setup_c.svg';
import classes from './steps.module.less';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ENTRA_ID_AD_CONNECT_ROUTE } from '../../constants/routes';

const { Content } = Layout;
const { Paragraph } = Typography;

interface FormValues {
  [aadTenantIdField]: string;
}

const ConnectAADStep: React.FC = function CaptureAADStep() {
  const [form] = Form.useForm<FormValues>();
  const onSubmitClick = useCallback(() => form.submit(), [form]);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const { pending, onSubmit } = useAADLinkRequest();
  const { t } = useTranslation();
  const history = useHistory<{
    aadTenantId?: string;
    entraIdError?: string;
  }>();
  const state = history.location.state;
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    const clearError = () => {
      history.replace(ENTRA_ID_AD_CONNECT_ROUTE, {
        ...state,
        entraIdError: undefined,
      });
      setInputValue(state?.aadTenantId as string);
    };
    const destroy = () => destroyBanner();

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        destroy();
      }
    };
    window.addEventListener('keydown', handleKeyDown);

    if (state?.entraIdError) {
      Banner({
        type: 'error',
        message: t('messages.errors.entra-id-invalid-primary' as never),
        description: t('messages.errors.entra-id-invalid-secondary' as never),
        onClose: clearError,
        onClick: clearError,
        duration: 10,
        datatestid: 'entra-id-error',
      });
    } else {
      destroy();
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      destroy();
    };
  }, [history, state, t]);

  const focusRef = useRef<InputRef>(null);

  useEffect(() => {
    focusRef.current?.focus();

    inputValue.length > 0
      ? setIsSubmitEnabled(true)
      : setIsSubmitEnabled(false);
  }, [inputValue]);

  return (
    <SetupStep
      data-testid="step-connect-aad"
      className={classes.aadConnect}
      rightPartBackground={backgroundImage}
    >
      <SetupStepHeader title={t('steps.capture-aad.title-label')} />
      <Content>
        <Paragraph className="primary-body">
          {t('steps.capture-aad.label-1')}
        </Paragraph>

        <Form
          name="aadConnectForm"
          form={form}
          layout="vertical"
          initialValues={{
            aadTenantId: state?.aadTenantId,
          }}
          requiredMark={false}
          onFinish={onSubmit}
        >
          <Form.Item
            required
            label={t('steps.capture-aad.tenant-input-label')}
            name={aadTenantIdField}
          >
            <InputBox
              autoFocus
              style={{
                width: '496px',
                color: '#333',
              }}
              placeholder=""
              defaultValue={inputValue}
              disabled={pending}
              size="small"
              data-testid="input-value"
              type="text"
              onChange={e => {
                setIsSubmitEnabled(e.target.value.length > 0);
              }}
            />
          </Form.Item>
        </Form>

        <Paragraph className={classes.button}>
          <Button
            disabled={!isSubmitEnabled}
            label={t('steps.capture-aad.next-btn')}
            type="primary"
            size="large"
            astraButtonType={['major']}
            data-testid="submit-btn"
            onClick={onSubmitClick}
          />
        </Paragraph>

        <Paragraph className="primary-body">
          {t('steps.capture-aad.label-2')}
        </Paragraph>
      </Content>
    </SetupStep>
  );
};

export default withTracing(ConnectAADStep);
