// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OqXv5yX2gpTchkJrlsDk {\n  width: 1120px;\n  min-height: 692px;\n  margin: 84px auto 0;\n  background-color: #fff;\n  box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);\n  display: flex;\n  align-items: stretch;\n}\n.VEUnNgk3yKszfl9iN7ai {\n  padding: 49px 32px 32px 44px;\n  flex-grow: 1;\n}\n.aSbp5tlV2nm1smIcG4vO {\n  background-color: #999dc1;\n  background-size: contain;\n  background-repeat: no-repeat;\n  background-position: center;\n  width: 460px;\n  flex-shrink: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/components/SetupStep.module.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,iBAAA;EACA,mBAAA;EACA,sBAAA;EACA,uCAAA;EACA,aAAA;EACA,oBAAA;AADF;AAIA;EACE,4BAAA;EACA,YAAA;AAFF;AAKA;EACE,yBAAA;EACA,wBAAA;EACA,4BAAA;EACA,2BAAA;EACA,YAAA;EACA,cAAA;AAHF","sourcesContent":["// Copyright 2022, Imprivata, Inc.  All rights reserved.\n\n.stepLayout {\n  width: 1120px;\n  min-height: 692px;\n  margin: 84px auto 0;\n  background-color: #fff;\n  box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);\n  display: flex;\n  align-items: stretch;\n}\n\n.leftPart {\n  padding: 49px 32px 32px 44px;\n  flex-grow: 1;\n}\n\n.rightPart {\n  background-color: #999dc1;\n  background-size: contain;\n  background-repeat: no-repeat;\n  background-position: center;\n  width: 460px;\n  flex-shrink: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stepLayout": "OqXv5yX2gpTchkJrlsDk",
	"leftPart": "VEUnNgk3yKszfl9iN7ai",
	"rightPart": "aSbp5tlV2nm1smIcG4vO"
};
export default ___CSS_LOADER_EXPORT___;
