// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import ImprivataLogo from './ImprivataLogo';

import classes from './SetupHeader.module.less';
import { useTranslation } from 'react-i18next';
import TestTenantLabel from './TestTenantLabel';
import { useSelector } from 'react-redux';
import { startSetupPendingSelector } from '../store/initialSetup/selectors';
import { StorageKeys, TenantType } from '../constants/fieldNames';
import storage from '../utils/storage';

const SetupHeader: React.FC<{ renderTitle: boolean }> = function SetupHeader({
  renderTitle,
}) {
  const { t } = useTranslation();

  // I need this to re-render once the /initial-setup/start finishes
  // to show <TestTenantLabel />
  useSelector(startSetupPendingSelector);

  return (
    <div data-testid="setup-header" className={classes.header}>
      <h1 className="h1-header title">
        {renderTitle && t('common.page-title', 'Imprivata setup')}
      </h1>
      <div>
        {storage.getItem(StorageKeys.IMPR_TENANT_TYPE) === TenantType.TEST && (
          <TestTenantLabel />
        )}
      </div>
      <ImprivataLogo width={150} />
    </div>
  );
};

export default SetupHeader;
