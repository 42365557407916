// Copyright 2022, Imprivata, Inc.  All rights reserved.

export interface ApiError {
  code: string;
  type: ApiErrorType;
  message?: string;
  data?: Record<string, unknown>;
}

export interface ErrorResponseBody {
  error: ApiError;
}

export enum ApiErrorType {
  APP = 'app',
  SYSTEM = 'system',
}

export enum ErrorCode {
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  SESSION_EXPIRED = 'session-expired',
  INVALID_TENANT_STATE = 'invalid-tenant-state',
}

export interface ApiResponseData {
  error?: {
    code?: string;
  };
}
