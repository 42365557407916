// Copyright 2022, Imprivata, Inc.  All rights reserved.

const KEY_PREFIX = 'initialSetupStorage';

const storage = {
  getItem: function (key: string): string | null {
    return sessionStorage.getItem(fullKey(key));
  },

  setItem: function (key: string, value: string): void {
    sessionStorage.setItem(fullKey(key), value);
    window.localStorage.setItem(fullKey(key), value);
  },

  removeItem: function (key: string): void {
    return sessionStorage.removeItem(fullKey(key));
  },

  clear: function (): void {
    return sessionStorage.clear();
  },

  isEmpty: function (): boolean {
    return sessionStorage.length === 0;
  },
};

export function fullKey(key: string): string {
  return KEY_PREFIX + '/' + key;
}

export default storage;
