import { Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import SetupStep from '../../components/SetupStep';
import withTracing from '../../tracing/withTracing';
import { ReactComponent as DeadLinkStateIcon } from '../../assets/icons/dead-link-init-setup.svg';

import { ADMIN_UI_URL } from '../../constants/services';
import { StorageKeys, adminUiTenantIdParam } from '../../constants/fieldNames';

import classes from '../steps/steps.module.less';

import { fullKey } from '../../utils/storage';
import { useTranslation } from 'react-i18next';

const { Content } = Layout;

const FullPageError: React.FC = () => {
  const { t } = useTranslation();
  const startTextMessage = t('messages.errors.link-dead-start-text-msg');
  const linkTextMessage = t('messages.errors.link-dead-text-msg');
  const endTextMessage = t('messages.errors.link-dead-end-text-msg');

  const [adminUiUrl, setAdminUiUrl] = useState<string>();
  const [storedTenantId, setStoredTenantId] = useState<string | null>(null);

  useEffect(() => {
    const fetchTenantId = async () => {
      try {
        const tenantId = window.localStorage.getItem(
          fullKey(StorageKeys.TENANT_ID),
        );
        setStoredTenantId(tenantId);
        setAdminUiUrl(
          `${ADMIN_UI_URL}?${adminUiTenantIdParam}=${storedTenantId}`,
        );
      } catch (error) {
        console.error('Error fetching tenantId:', error);
      }
    };

    fetchTenantId();
  }, [storedTenantId]);

  window.addEventListener('pageshow', function (event) {
    if (event.persisted) {
      window.location.reload();
    }
  });

  return (
    <SetupStep
      data-testid="full-page-error"
      className={classes.fullErrorPage}
      showRightPart={false}
    >
      <Content
        data-testid="full-page-error-message"
        className={classes.messageContent}
      >
        <DeadLinkStateIcon />

        <p className={classes.pageLinkDesc}>
          {startTextMessage}
          <a
            data-testid="link-to-admin-ui"
            rel="noopener noreferrer"
            href={adminUiUrl}
          >
            {linkTextMessage}
          </a>
          {endTextMessage}
        </p>
      </Content>
    </SetupStep>
  );
};

export default withTracing(FullPageError);
