// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';

import logoSrc from '../assets/logos/imprivata.svg';

interface LogoProps {
  width?: number;
  height?: number;
  className?: string;
}

const ImprivataLogo: React.FC<LogoProps> = function ImprivataLogo({
  width,
  height,
  className,
}) {
  return (
    <img
      alt="Imprivata"
      className={className}
      width={width}
      height={height}
      src={logoSrc}
      data-testid="imprivataLogo"
    />
  );
};

export default ImprivataLogo;
