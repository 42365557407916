// Copyright 2022, Imprivata, Inc.  All rights reserved.

import ErrorImpl from '../../service/errorImpl';
import { RootState } from '../rootReducer';

export const sessionIdSelector = ({ initialSetup }: RootState): string =>
  initialSetup.sessionId;
export const aadLinkPendingSelector = ({ initialSetup }: RootState): boolean =>
  initialSetup.aadLinkPending;

export const aadLinkRequestErrorSelector = ({
  initialSetup,
}: RootState): ErrorImpl | null => initialSetup.aadLinkRequestError;

export const adminAuthPendingSelector = ({
  initialSetup,
}: RootState): boolean => initialSetup.adminAuthPending;

export const dpaCheckErrorSelector = ({
  initialSetup,
}: RootState): ErrorImpl | null => initialSetup.dpaCheckError;

export const dpaSubmitErrorSelector = ({
  initialSetup,
}: RootState): ErrorImpl | null => initialSetup.dpaSubmitError;

export const adminAuthErrorSelector = ({
  initialSetup,
}: RootState): ErrorImpl | null => initialSetup.adminAuthError;

export const dpaNoActionNeededSelector = ({
  initialSetup,
}: RootState): boolean => initialSetup.dpaNoActionNeeded;

export const startSetupPendingSelector = ({
  initialSetup,
}: RootState): boolean => initialSetup.startSetupPending;

export const startSetupErrorSelector = ({
  initialSetup,
}: RootState): ErrorImpl | null => initialSetup.startSetupError;

export const startSetupSessionSelector = ({
  initialSetup,
}: RootState): string => initialSetup.startSetupSuccess;
