// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { API_TIMEOUT, API_URL, headers } from '../constants/services';
import { AxiosRequestConfig } from 'axios';
import { jsonToBase64 } from '@imprivata-cloud/data-privacy-js';
import { WEB_APP_NAME } from '../constants/constants';

export default {
  baseURL: API_URL,
  timeout: API_TIMEOUT,
  withCredentials: true,
  headers: {
    [headers.ImprClientName]: jsonToBase64({ name: WEB_APP_NAME }),
  },
} as AxiosRequestConfig;
