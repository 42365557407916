// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import tracingSetup from './tracing/tracingSetup';

import packageJson from '../package.json';

import './styles/index.less';
import './index.less';

tracingSetup(packageJson.name);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
