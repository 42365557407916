// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import { Result, Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import { QuestionCircleOutlined } from '@ant-design/icons';
import SetupStep from '../../components/SetupStep';

import SetupStepHeader from '../../components/SetupStepHeader';

import classes from '../steps/steps.module.less';

const { Content } = Layout;

const NotFoundPage: React.FC = function NotFoundPage() {
  const { t } = useTranslation();
  return (
    <SetupStep data-testid="not-found-page">
      <SetupStepHeader title={t('messages.errors.not-found.title')} />
      <Content className={classes.messageContent}>
        <Result
          title={t('messages.errors.not-found.description')}
          icon={<QuestionCircleOutlined />}
        />
      </Content>
    </SetupStep>
  );
};

export default NotFoundPage;
