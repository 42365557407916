// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import { BaseOpenTelemetryComponent } from '@opentelemetry/plugin-react-load';

export default function withTracing<T>(
  WrappedComponent: React.ComponentType<T>,
): React.ComponentType<T> {
  const componentName = WrappedComponent?.displayName ?? WrappedComponent.name;
  class TracingWrapper extends BaseOpenTelemetryComponent {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(props: any) {
      super(props);
      this.moduleName = componentName;
    }

    render() {
      return this.props.children;
    }
  }
  const WithTracingWrappedComponent: React.FC<T> = (props: T) => (
    <TracingWrapper>
      <WrappedComponent as T {...props} />
    </TracingWrapper>
  );
  WithTracingWrappedComponent.displayName = `WithTracing${componentName}`;

  return WithTracingWrappedComponent;
}
