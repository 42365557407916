// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Zy2MsGHREwVQUzeCB4pi {\n  display: flex;\n  flex-direction: column;\n}\n.Zy2MsGHREwVQUzeCB4pi .jF4D7AYNGr7f_F45eR8x {\n  flex-grow: 1;\n  background-color: #fafafa;\n  display: flex;\n  justify-content: center;\n  align-items: flex-start;\n}\n", "",{"version":3,"sources":["webpack://./src/components/SetupLayout.module.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;AADF;AADA;EAKI,YAAA;EACA,yBAAA;EACA,aAAA;EACA,uBAAA;EACA,uBAAA;AADJ","sourcesContent":["// Copyright 2022, Imprivata, Inc.  All rights reserved.\n\n.setupLayout {\n  display: flex;\n  flex-direction: column;\n\n  .setupContent {\n    flex-grow: 1;\n    background-color: #fafafa;\n    display: flex;\n    justify-content: center;\n    align-items: flex-start;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"setupLayout": "Zy2MsGHREwVQUzeCB4pi",
	"setupContent": "jF4D7AYNGr7f_F45eR8x"
};
export default ___CSS_LOADER_EXPORT___;
