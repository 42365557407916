// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { createBrowserHistory } from 'history';
import getConfig from '../appEnvConfig';

const { PUBLIC_URL } = getConfig();

const history = createBrowserHistory({
  basename: PUBLIC_URL,
});

export default history;
