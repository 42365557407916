// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { useLocation } from 'react-router-dom';
import queryString, { ParseOptions } from 'query-string';

export default function useQueryParams(
  parseOptions?: ParseOptions,
): ReturnType<typeof queryString.parse> {
  const { search } = useLocation();

  return queryString.parse(search, parseOptions);
}
