// Copyright 2022, Imprivata, Inc.  All rights reserved.

// Query params names
export const sessionIdParam = 'session_id' as const;
export const bearerTokenParam = 'bt' as const;
export const tenantIdParam = 'tid' as const;
export const adminUiTenantIdParam = 'tenantId' as const;

// Data fields names
export const aadTenantIdField = 'aadTenantId' as const;
export const adminUsernameField = 'username' as const;

// LocalStorage keys names
export enum StorageKeys {
  AAD_TENANT_ID = 'AAD_TENANT_ID',
  TENANT_ID = 'TENANT_ID',
  CODING_CTX = 'CODING_CTX',
  IMPR_TENANT_TYPE = 'IMPR_TENANT_TYPE', // see TenantType (PRIMARY, TEST)
}

export enum TenantType {
  PRIMARY = 'PRIMARY',
  TEST = 'TEST',
}
