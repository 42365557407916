// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { applyMiddleware, compose, createStore } from 'redux';
import { createEpicMiddleware, Epic } from 'redux-observable';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { History } from 'history';
import { Action } from './rootAction';
import rootEpic from './rootEpic';
import rootReducer, { RootState } from './rootReducer';

export type Store = ReturnType<typeof configureStore>;

type ConfigOptions = {
  initialState?: RootState;
  isProduction?: boolean;
  module?: __WebpackModuleApi.Module;
  history: History;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function configureStore(options?: ConfigOptions) {
  const { initialState, isProduction = false, module, history } = options ?? {};
  const epicMiddleware = createEpicMiddleware<Action, Action, RootState>({
    dependencies: {
      history,
    },
  });

  const middlewareEnhancer = applyMiddleware(epicMiddleware);

  const composedEnhancers = compose(middlewareEnhancer);

  const store = createStore(rootReducer, initialState, composedEnhancers);

  if (!isProduction && module?.hot) {
    const epic$ = new BehaviorSubject(rootEpic);
    // Every time a new epic is given to epic$ it
    // will unsubscribe from the previous one then
    // call and subscribe to the new one because of
    // how switchMap works
    const hotReloadingEpic: Epic<Action> = (...args) =>
      epic$.pipe(switchMap(epic => epic(...args)));

    epicMiddleware.run(hotReloadingEpic);

    module.hot.accept('./rootReducer', () => store.replaceReducer(rootReducer));
    module.hot.accept('./rootEpic', () => {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const nextRootEpic = require('./rootEpic').default;
      epic$.next(nextRootEpic);
    });
  } else {
    epicMiddleware.run(rootEpic);
  }

  return store;
}
