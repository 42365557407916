// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { combineReducers } from 'redux';

import appReducer from './app/reducer';
import initialSetup from './initialSetup/reducer';

const rootReducer = combineReducers({
  app: appReducer,
  initialSetup,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
