// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".j8TxRo9Kv4HmoPZzNebX.ant-layout {\n  min-height: 100vh;\n  display: flex;\n}\n.LMMJwMsscnkueB4wPsGT {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n.zuAQS5kTuj1hC1ZFTKVg {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n.zuAQS5kTuj1hC1ZFTKVg .JByUtKun5Ef49bY2OHba {\n  flex-grow: 1;\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n}\n.zuAQS5kTuj1hC1ZFTKVg .nw4wcL0QoEh2xavBAFiI .ant-menu-item-group-title,\n.zuAQS5kTuj1hC1ZFTKVg .JByUtKun5Ef49bY2OHba .ant-menu-item-group-title,\n.zuAQS5kTuj1hC1ZFTKVg .eqXHyoqgz4O83tn4qkX5 .ant-menu-item-group-title {\n  display: none;\n}\n", "",{"version":3,"sources":["webpack://./src/containers/AppLayout.module.less"],"names":[],"mappings":"AAGE;EACE,iBAAA;EACA,aAAA;AAFJ;AAMA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;AAJF;AAOA;EACE,YAAA;EACA,aAAA;EACA,sBAAA;AALF;AAEA;EAMI,YAAA;EACA,aAAA;EACA,uBAAA;EACA,sBAAA;AALJ;AAJA;;;EAgBM,aAAA;AAPN","sourcesContent":["// Copyright 2022, Imprivata, Inc.  All rights reserved.\n\n.appLayout {\n  &:global(.ant-layout) {\n    min-height: 100vh;\n    display: flex;\n  }\n}\n\n.loading {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n\n.menu {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n\n  .midGroup {\n    flex-grow: 1;\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n  }\n\n  .topGroup,\n  .midGroup,\n  .bottomGroup {\n    :global(.ant-menu-item-group-title) {\n      display: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appLayout": "j8TxRo9Kv4HmoPZzNebX",
	"loading": "LMMJwMsscnkueB4wPsGT",
	"menu": "zuAQS5kTuj1hC1ZFTKVg",
	"midGroup": "JByUtKun5Ef49bY2OHba",
	"topGroup": "nw4wcL0QoEh2xavBAFiI",
	"bottomGroup": "eqXHyoqgz4O83tn4qkX5"
};
export default ___CSS_LOADER_EXPORT___;
