// Copyright 2022, Imprivata, Inc.  All rights reserved.

import {
  aadTenantIdField,
  adminUsernameField,
  StorageKeys,
} from '../../constants/fieldNames';

export type GetAADLinkParams = {
  [aadTenantIdField]: string;
};

export type AadConsentUrlGetResponse = {
  aadConsentUrl: string;
};

export type AdminAuthParams = {
  [adminUsernameField]: string;
  [StorageKeys.CODING_CTX]?: string | unknown;
};

export type CompleteStepRequest = {
  [adminUsernameField]: string;
};

export type Directory = {
  name: string;
  directoryId: string;
  latestSyncAt?: string;
  syncedGroupCount: number;
  idpTenantId: string;
};

export interface DirectoriesFindData {
  directories: Directory[];
}

export type InitialGroupSyncStartRequest = {
  identityProviderId: string;
};

export type StartSetupParams = {
  bearerToken: string;
};

export interface CheckDpaConsentRequest {
  consentDocumentName: string;
}
export interface CheckDpaConsentResponse {
  actionType: CheckDpaConsentResponseActionType;
}
export enum CheckDpaConsentResponseActionType {
  NONE = 'NONE', // Don't show the clickthrough
  CHECK_NEEDED = 'CHECK_NEEDED',
  UPDATE_NEEDED = 'UPDATE_NEEDED',
}

export interface ConsentDpaSubmitRequest {
  grantType: 'granted';
  documentName: string;
  // the value from the final initial admin form
  userId: string;
}
