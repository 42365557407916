// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import classes from './TestTenantLabel.module.less';
import { useTranslation } from 'react-i18next';

const TestTenantLabel: React.FC<unknown> = function TestTenantLabel() {
  const { t } = useTranslation();
  return (
    <span className={classes.testTenantLabel} data-testid="testTenantLabel">
      {t('common.test-tenant-label')}
    </span>
  );
};

export default TestTenantLabel;
